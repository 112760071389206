/* Globales */
*::after,
*::before,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  /* max-width: 1200px; */
  font-family: "Lato";
  padding-left: 10px;
  padding-right: 10px;
  color: #333;
}

.icon-cart {
  width: 40px;
  height: 40px;
  stroke: #000;
}

.icon-cart:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
}

/* Header */
header {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 40px 0;
}

.container-icon {
  position: relative;
}

.count-products {
  position: absolute;
  top: 55%;
  right: 0;

  background-color: #000;
  color: #fff;
  width: 25px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

#contador-productos {
  font-size: 12px;
}

.container-cart-products {
  position: absolute;
  top: 50px;
  right: -3px;

  background-color: #fff;
  width: 350px;
  z-index: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.cart-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.info-cart-product {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.titulo-producto-carrito {
  font-size: 20px;
}

.precio-producto-carrito {
  font-weight: 700;
  font-size: 20px;
  margin-left: 10px;
}

.cantidad-producto-carrito {
  font-weight: 400;
  font-size: 20px;
}

.icon-close {
  width: 25px;
  height: 25px;
}

.icon-close:hover {
  stroke: red;
  cursor: pointer;
}

.cart-total {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  gap: 20px;
}

.cart-total h3 {
  font-size: 20px;
  font-weight: 700;
}

.total-pagar {
  font-size: 20px;
  font-weight: 900;
}

.hidden-cart {
  display: none;
}

/* Main */
.container-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.item {
  border-radius: 10px;
}

.item:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  transition: all 0.5s;
}

.item figure {
  overflow: hidden;
}

.item:hover img {
  transform: scale(1.2);
}

.info-product {
  padding: 15px 30px;
  line-height: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.price {
  font-size: 18px;
  font-weight: 900;
}

.info-product button {
  border: none;
  background: none;
  background-color: #000;
  color: #fff;
  padding: 15px 10px;
  cursor: pointer;
}

.btn-clear-all {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 15px 0;
  display: block;
  width: 90%;
  margin: 10px auto; /* Margen superior e inferior de 10px y margen izquierdo y derecho automático */
  border-radius: 20px; /* Valor igual o mayor a la mitad de la altura del botón */
  font-family: inherit;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  text-align: center; /* Centrar el contenido del botón horizontalmente */
}

.btn-clear-all:hover {
  background-color: #333;
  transform: scale(1.03);
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn-comprar-all {
  border: none;
  margin: 10px auto; /* Margen superior e inferior de 10px y margen izquierdo y derecho automático */
  text-align: center; /* Centrar el contenido del botón horizontalmente */
  background-color: rgb(0, 131, 37);
  color: #fff;
  padding: 15px 0;
  display: block;
  width: 90%;
  margin-top: 10px;
  border-radius: 20px; /* Valor igual o mayor a la mitad de la altura del botón */
  font-family: inherit;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}

.btn-comprar-all:hover {
  background-color: #333;
  transform: scale(1.03);
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.menu li a:hover {
  color: #222;
}

.menu li {
  display: inline-block;
  list-style: none;
}

.menu li a {
  color: #fff;
  list-style: none;
  font-size: 18px;
  font-weight: 500;
  margin-left: 25px;
  transition: color 0.3s;
}
