@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('@lineation/icons/fonts/lineation.css'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.filtersMobil {
  /* padding-left: 40%; */
  /* text-align: center; */
  display: flex;
  /* justify-content: center; */
  overflow-x: auto;
  white-space: nowrap;
}
.filters {
  text-align: center;
  display: flex;
  /* justify-content: center; */
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
}
