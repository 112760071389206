.cart {
  background: #ffffff;
  display: none;
  padding: 32px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 300px;
}

.cart img {
  aspect-ratio: 16/9;
  width: 100%;
}

.cart li {
  border-bottom: 1px solid #444;
  padding-bottom: 16px;
}

.cart footer {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.cart footer button {
  padding: 8px;
}

.cart-button {
  align-items: center;
  /* background: #09f; */
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 4px;
  /* position: absolute; */
  right: 8px;
  top: 8px;
  transition: all .3s ease;
  width: 32px;
  z-index: 9999;
}

.cart-button:hover {
  scale: 1.1;
}

.cart-button ~ input:checked ~ .cart {
  height: 100%;
  display: block;
}